<template>
  <button
    :type="type"
    :class="[
      'font-medium rounded-full transition-all duration-200',
      sizeClass,
      variantClass,
      sizeClass,
      widthClass,
      { 'pointer-events-none': isDisabled },
      { '!min-w-[215px]': hasMinWidth },
    ]"
    :disabled="isDisabled"
    :data-testid="dataTestid"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    dataTestid: {
      type: String,
      default: null,
    },

    type: {
      type: String,
      default: 'button',
      required: false,
    },

    variant: {
      type: String,
      default: 'primary',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'large',
    },

    hasMinWidth: {
      type: Boolean,
      default: false,
    },

    // width prop is a tailwind width class for now
    width: {
      type: String,
      default: 'auto',
    },
  },

  computed: {
    variantClass() {
      switch (this.variant) {
        case 'error':
          return [
            'bg-white text-red-100 hover:bg-red-100 hover:text-white border-2 border-red-100',
          ];

        case 'white':
          return [
            'bg-white text-ol-primary hover:bg-ol-dark-200 hover:text-white border-2 border-white hover:border-ol-dark-200',
            { 'text-ol-primary-20 border-ol-primary-20': this.isDisabled },
          ];

        case 'secondary':
          return [
            'bg-white text-ol-primary hover:bg-ol-primary-10 hover:text-ol-primary border-2 border-ol-primary',
            { 'text-ol-primary-20 !border-ol-primary-20 ': this.isDisabled },
          ];
        case 'tertiary':
          return [
            'bg-white text-ol-dark-ink-200 hover:bg-ol-primary-10 hover:text-ol-primary border-2 border-ol-dark-ink-200',
            { 'text-ol-primary-20 border-ol-primary-20': this.isDisabled },
          ];
        case 'dark':
          return [
            'bg-ol-dark-ink-200 text-white hover:bg-transparent hover:text-ol-dark-ink-200 border-2 border-ol-dark-ink-200',
            { 'text-ol-primary-20 border-ol-primary-20 ': this.isDisabled },
          ];
        case 'pink':
          return [
            'bg-ol-pink text-ol-dark-ink-200 hover:bg-ol-accent-1-100 hover:text-white ',
            { 'bg-ol-accent-1-20': this.isDisabled },
          ];

        case 'transparent':
          return [
            'bg-transparent text-ol-primary-100 hover:bg-ol-primary-10 hover:text-ol-primary-100 border-2 border-ol-primary-100',
            { 'text-ol-primary-20 border-ol-primary-20': this.isDisabled },
          ];
        case 'sectionCta': {
          return [
            `xl:px-10 md:px-8 py-4 text-center bg-ol-dark-ink-200
              text-white hover:bg-ol-accent-1-100    transition-colors
              duration-150 ease-in-out rounded-full border border-white hover:border-ol-accent-1-100 xl:py-5  font-medium
              whitespace-nowrap  items-center justify-center;
             `,
            { 'opacity-25': this.isDisabled },
          ];
        }

        case 'primary':
        default:
          return [
            'bg-ol-primary text-white hover:bg-ol-dark border-2 border-transparent',
            {
              'text-white bg-ol-primary-20 border-ol-primary-20 opacity-100':
                this.isDisabled,
            },
          ];
      }
    },

    sizeClass() {
      switch (this.size) {
        case 'xs':
          return ['text-xs py-1 px-4'];
        case 'small':
          return ['text-sm py-3 px-4'];
        case 'medium':

        default:
          return 'py-5 px-8 text-base lg:text-lg lg:py-5 !leading-[0.5]';
      }
    },
    widthClass() {
      return this.width ? `w-${this.width}` : '';
    },
  },
};
</script>
