<template>
  <svg
    viewBox="0 0 269 39"
    xmlns="http://www.w3.org/2000/svg"
    :class="logoColour"
  >
    <path d="m155.23 29.403v-29.403h-1.709v29.403h1.709z" />
    <path
      d="m160.94 19.042h17.181c0.076-6.0975-3.632-10.606-9.17-10.606-5.753 0-9.751 4.4166-9.751 10.728 0 6.3421 4.242 10.728 10.376 10.728 3.616 0 6.668-1.4976 8.209-3.3773v-2.0937c-1.419 2.216-4.532 3.9276-8.041 3.9276-5.295 0-8.804-3.79-8.804-9.1846v-0.1223zm0.076-1.467c0.595-4.6305 3.586-7.5953 7.889-7.5953 4.165 0 7.171 3.2093 7.461 7.5953h-15.35z"
      clip-rule="evenodd"
      fill-rule="evenodd"
    />
    <path
      d="m202.96 8.9248h-1.709v5.6544c-1.297-3.7595-4.76-6.1435-9.094-6.1435-5.722 0-10.345 4.5388-10.345 10.392 0 5.8072 4.639 10.361 10.345 10.361 4.334 0 7.797-2.384 9.094-6.0976v5.3029c0 4.875-3.616 8.2218-8.499 8.2218-2.929 0-6.21-1.406-8.468-3.6677v1.8338c2.258 2.0784 5.676 3.3774 8.59 3.3774 5.875 0 10.086-3.9734 10.086-9.7347v-19.5zm-1.693 9.9028c0 5.0125-3.8 8.7719-8.804 8.7719-4.959 0-8.835-3.7594-8.835-8.7719 0-5.0584 3.876-8.8178 8.835-8.8178 5.004 0 8.804 3.7594 8.804 8.8178z"
      clip-rule="evenodd"
      fill-rule="evenodd"
    />
    <path
      d="m224.4 16.581c0-5.0584-3.296-8.1454-8.59-8.1454-2.579 0-5.371 1.2073-7.126 3.3927v1.9255c1.74-2.3381 4.41-3.7135 6.958-3.7135 4.379 0 7.049 2.4604 7.049 6.6018v2.6286c-1.388-1.4977-4.013-2.6286-6.927-2.6286-4.364 0-7.706 2.7967-7.706 6.6325 0 3.79 3.083 6.6324 7.675 6.6324 3.113 0 5.783-1.9714 6.958-4.4318v3.9275h1.709v-12.822zm-1.709 4.7833c-0.046 3.9275-2.99 7.0604-6.668 7.0604-3.677 0-6.21-2.2465-6.21-5.2112 0-2.9648 2.579-5.1807 6.21-5.1807 2.793 0 5.539 1.2379 6.668 3.0412v0.2903z"
      clip-rule="evenodd"
      fill-rule="evenodd"
    />
    <path
      d="m246.95 11.737c-1.755-2.1701-4.547-3.301-7.339-3.301-5.966 0-10.513 4.6305-10.513 10.728s4.547 10.728 10.513 10.728c2.792 0 5.66-1.2072 7.461-3.469v-2.1242c-1.785 2.598-4.532 3.9733-7.416 3.9733-5.004 0-8.758-3.8816-8.758-9.1081s3.754-9.1082 8.758-9.1082c2.96 0 5.585 1.3449 7.294 3.8053v-2.1242z"
    />
    <path
      d="m269 8.9248h-1.8l-8.057 18.415-7.919-18.415h-1.877l8.926 20.386-4.211 9.6889h1.709l13.229-30.075z"
    />
    <path
      d="m106.9 28.151c5.096 2.3076 10.924 0.7794 14.144-3.6524 0.34 0.2461 0.669 0.4975 0.995 0.7465 0.787 0.6011 1.555 1.1881 2.408 1.6528 3.951 2.17 8.224 2.5674 12.603 1.9713 1.19-0.1681 2.395-0.6418 3.448-1.2225 2.442-1.3449 3.647-4.1873 2.991-6.7547-0.656-2.5063-2.579-3.6219-4.883-4.1262-1.14-0.2494-2.298-0.4085-3.459-0.568-0.688-0.0946-1.377-0.1893-2.064-0.3031-0.111-0.0187-0.221-0.0372-0.332-0.0557-0.887-0.1482-1.789-0.2992-2.644-0.5708-0.747-0.2293-1.236-0.81-1.281-1.6505-0.046-0.8864 0.351-1.513 1.083-1.9103 0.519-0.2751 1.099-0.5349 1.678-0.596 2.961-0.3668 5.844-0.0764 8.53 1.3296 1.159 0.6112 1.587 0.489 2.136-0.703 0.412-0.8711 0.32-1.6963-0.443-2.2923-0.61-0.45846-1.297-0.8558-2.014-1.1156-2.7-0.97805-5.523-1.1309-8.331-0.73354-1.464 0.21395-2.99 0.56544-4.318 1.2073-2.121 1.0239-2.99 2.9647-2.929 5.3182 0.061 2.2006 1.159 3.7594 3.036 4.7222 1.007 0.5196 2.151 0.8558 3.281 1.0697 0.925 0.1769 1.863 0.2915 2.801 0.4061 1.088 0.1329 2.176 0.2658 3.241 0.4956 0.732 0.1528 1.587 0.7488 1.953 1.3906 0.763 1.3143-0.107 2.705-1.953 2.9495-1.77 0.2292-3.586 0.1987-5.356 0.0458-5.508-0.4584-9.262-4.6304-9.277-10.178 0-0.4853 1e-3 -0.9706 2e-3 -1.4559v-0.0023c3e-3 -1.4547 6e-3 -2.9095-0.017-4.3642-0.015-0.27508-0.183-0.79467-0.29-0.79467-0.228-0.01544-0.459-0.03587-0.69-0.05637-0.914-0.08098-1.842-0.16314-2.682 0.05637-0.978 0.25241-0.977 1.3415-0.977 2.2722v0.0833c0.016 3.071 0.031 6.142-0.015 9.213-0.046 2.3993-1.358 4.0498-3.586 4.6458-4.043 1.1003-7.064-1.1767-7.095-5.3487-2e-3 -0.5796-6e-3 -1.1591-0.01-1.7387v-0.0115c-0.017-2.6259-0.034-5.2517 0.041-7.8776 0.03-1.0698-0.397-1.4518-1.343-1.5282-0.126-0.00504-0.253-0.0134-0.381-0.02179-0.26-0.01708-0.523-0.03431-0.778-0.02406-1.374 0.03056-2.137 0.71826-2.137 2.0783-3e-3 0.7335-8e-3 1.467-0.014 2.2005-0.022 2.9342-0.044 5.8685 0.091 8.8027 0.137 3.2551 1.846 5.6391 4.837 6.9992z"
    />
    <path
      d="m43.008 7.8085v-4.768h4.669v4.7527h5.7066v4.0498h-5.493c-0.0239 0.0479-0.0478 0.0898-0.0694 0.1275-0.0472 0.0827-0.0831 0.1458-0.0831 0.2087-0.0026 0.5741-0.0055 1.1483-0.0085 1.7225v0.0029 0.0011c-0.0149 2.8786-0.0297 5.759 0.0085 8.65 0.0152 1.3601 1.0528 2.3229 2.5786 2.4452 0.6943 0.0527 1.3885 0.04 2.0978 0.027 0.3195-0.0058 0.6421-0.0117 0.9691-0.0117v3.8969c-2.0141 0.3821-3.9519 0.4279-5.8744-0.1834-2.7465-0.8711-4.4097-3.1787-4.4554-6.2656-0.0367-2.4818-0.0342-4.9635-0.0318-7.4453 6e-4 -0.6205 0.0012-1.241 0.0012-1.8616v-1.1156c-0.9518 0-1.8963-0.0277-2.8336-0.0551-2.0978-0.0615-4.1591-0.122-6.184 0.1315-3.3416 0.4127-5.5845 3.7136-5.1878 6.8923 0.4425 3.5913 3.189 6.0364 6.7594 5.9447 0.7788-0.0236 1.5668-0.0929 2.3921-0.1656 0.2418-0.0212 0.4869-0.0428 0.7359-0.0636v3.9275c-4.1656 1.192-9.155 0.0764-11.657-2.6438-3.0516-3.3162-4.0434-7.1368-2.365-11.416 1.6632-4.2485 4.8674-6.5714 9.4296-6.77 1.787-0.07548 3.574-0.05967 5.3663-0.04381 0.7481 0.00662 1.4972 0.01324 2.2476 0.01324 0.3662 0.01528 0.7476 0.01528 1.2817 0.01528z"
    />
    <path
      d="m83.208 26.453v-2e-4c0.405 0.2269 0.8008 0.4737 1.1952 0.7196 0.8538 0.5323 1.7009 1.0604 2.6194 1.374 4.3333 1.513 9.3685-0.2903 11.81-4.065 2.6396-4.0956 2.2586-9.9487-0.9003-13.509-2.9601-3.3468-7.919-4.4624-12.527-2.8272-4.1808 1.4671-6.7442 5.0737-6.851 10.056-0.0933 4.5343-0.0707 9.0685-0.048 13.603 0.0069 1.3952 0.0139 2.7904 0.0175 4.1856 0 0.2598 0.1221 0.7336 0.2136 0.7489 0.2377 0.0198 0.4769 0.0447 0.7166 0.0696h2e-4c0.8678 0.0902 1.7421 0.181 2.579 0.0373 0.946-0.1681 1.0986-1.1614 1.0986-2.0478 0.0076-1.2528 0.0115-2.5057 0.0153-3.7585 0.0038-1.2535 0.0076-2.5069 0.0152-3.7603 0-0.3208 0.0305-0.6568 0.0458-0.825zm8.9261-14.335c2.5939 1.1921 3.5247 3.4538 3.6009 5.5933-0.0152 5.1959-3.9061 8.2982-8.1021 7.0604-3.5857-1.0392-5.4777-5.2724-4.074-9.1235 1.2665-3.4843 5.1573-5.1195 8.5752-3.5302z"
      clip-rule="evenodd"
      fill-rule="evenodd"
    />
    <path
      d="m11.165 29.097c-4.8064 0-8.9109-2.7813-10.498-7.1062-1.5563-4.2484-0.33568-9.047 3.0974-11.905 6.0728-5.0583 16.464-2.659 18.463 5.578 1.4953 6.1587-2.5024 13.433-11.062 13.433zm0.1068-4.0803c3.6162 0 6.3779-2.8883 6.4084-6.7089 0.0306-3.9428-2.6396-6.77-6.3779-6.7852-3.7078-0.0153-6.439 2.873-6.4085 6.77 0.01526 3.8205 2.7618 6.7241 6.378 6.7241z"
      clip-rule="evenodd"
      fill-rule="evenodd"
    />
    <path
      d="m55.722 22.981c1.9988 4.1567 5.5693 5.9753 9.3838 6.1128 6.9731-0.0305 11.703-4.3095 11.978-10.224 0.2288-4.8444-2.4566-8.9706-6.9425-10.636-4.8369-1.8033-10.406-0.44319-13.29 3.3927-2.6397 3.5149-3.0211 7.4118-1.1291 11.355zm10.101-11.462c3.7383-0.0306 6.4237 2.7661 6.4237 6.7241 0 3.8358-2.716 6.7394-6.3169 6.7547-3.6925 0.0153-6.4238-2.8425-6.4238-6.7547 0-3.8969 2.6092-6.6935 6.317-6.7241z"
      clip-rule="evenodd"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'LogoOctopusLegacy',

  props: {
    colour: {
      type: String,
      default: 'light',
    },
  },

  setup(props) {
    const logoColour = computed(() => {
      if (props.colour === 'dark') {
        return 'fill-ol-dark-ink-200';
      } else return 'fill-white';
    });

    return { logoColour };
  },
};
</script>
