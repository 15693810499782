<template>
  <div class="mobile-menu">
    <header class="mobile-menu__header">
      {{ navItems.title }}
      <ChevronDown class="my-auto"></ChevronDown>
    </header>

    <a
      class="p-3"
      :href="item.route"
      :key="item.name"
      v-for="item in navItems.items"
    >
      <div class="flex gap-4">
        <keep-alive>
          <component class="mobile-menu__icon" :is="item.icon" />
        </keep-alive>
        <div class="flex flex-col gap-2">
          <header :class="navItems.itemsClass" class="mobile-menu__item-title">
            {{ item.name }}
          </header>
          <p class="mobile-menu__item-description" v-if="item.description">
            {{ item.description }}
          </p>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import MainNavDropdownTitle from './partials/MainNavDropdownTitle.vue';
import MainNavDropdownList from './partials/MainNavDropdownList.vue';
import MainNavDropdownListLink from './partials/MainNavDropdownListLink.vue';
import Pencil from '@components/Icon/Pencil.vue';
import House from '@components/Icon/House.vue';
import Clipboard from '@components/Icon/Clipboard.vue';
import Gift from '@components/Icon/Gift.vue';
import Scale from '@components/Icon/Scale.vue';
import Phone from '@components/Icon/Phone.vue';
import ChatBubble from '@components/Icon/ChatBubble.vue';
import Sparkles from '@components/Icon/Sparkles.vue';
import Briefcase from '@components/Icon/Briefcase.vue';
import ChevronDown from '../Icon/ChevronDown.vue';
import Present from '@components/Icon/Present.vue';

export default {
  components: {
    ChevronDown,
    MainNavDropdownListLink,
    MainNavDropdownList,
    MainNavDropdownTitle,
    Pencil,
    House,
    Clipboard,
    Gift,
    Scale,
    Phone,
    ChatBubble,
    Sparkles,
    Briefcase,
    Present,
  },
  props: ['navItems'],
};
</script>

<style>
.mobile-menu {
  @apply p-5 text-ol-dark-ink-200;
}

.mobile-menu__header {
  @apply text-2xl flex;
}

.mobile-menu__icon {
  @apply my-auto w-7 h-7 lg:h-5 lg:w-5;
}

.mobile-menu__item-title {
  @apply text-xl;
}

.mobile-menu__item-description {
  @apply text-dark-pewter-50 font-medium;
}
</style>
