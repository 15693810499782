<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6562 0C24.2076 0 24.72 0.512401 24.72 7.0638V16.9356C24.72 23.487 24.2076 24 17.6562 24H16.4028V15.2628H19.4832L19.9674 11.8008H16.4028C16.4028 11.8008 16.3974 9.774 16.4028 9.2682C16.413 8.2788 17.2392 7.7784 17.949 7.7856C18.6588 7.7928 20.1294 7.788 20.1294 7.788V4.5978C20.1294 4.5978 18.8556 4.4328 17.52 4.419C16.3974 4.4076 15.1578 4.7112 14.1576 5.7192C13.14 6.7446 12.9798 8.2716 12.96 10.1376C12.9546 10.6776 12.96 11.8008 12.96 11.8008H9.94557V15.2628H12.96V24H7.78437C1.23237 24 0.719971 23.487 0.719971 16.9362V7.0638C0.719971 0.512401 1.23237 0 7.78377 0H17.6562Z"
      fill="white"
    />
  </svg>
</template>
