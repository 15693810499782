<script setup>
import { computed } from 'vue';

const { colour = 'light' } = defineProps({
  colour: String,
});

const logoColour = computed(() =>
  colour === 'dark' ? 'fill-ol-dark-ink-200' : 'fill-ol-light'
);
</script>

<template>
  <svg
    viewBox="0 0 200 34"
    xmlns="http://www.w3.org/2000/svg"
    :class="logoColour"
  >
    <g id="Layer 1">
      <path
        id="Vector"
        d="M107.231 21.9745C104.38 25.8603 99.218 27.2194 94.7105 25.1904C92.0523 24.0036 90.5498 21.898 90.4342 19.0458C90.2801 15.83 90.3572 12.595 90.3572 9.37919C90.3572 8.1924 91.0313 7.57986 92.2449 7.56072C92.5916 7.54157 92.9383 7.57986 93.2658 7.599C94.0941 7.67557 94.4794 8.00098 94.4601 8.93893C94.383 11.7528 94.4216 14.5858 94.4408 17.3996C94.4601 21.0749 97.1376 23.0656 100.72 22.1085C102.685 21.5917 103.86 20.1369 103.899 18.0313C103.937 15.3323 103.918 12.6333 103.918 9.9343C103.918 9.11121 103.899 8.09669 104.785 7.86699C105.709 7.61814 106.769 7.80956 107.771 7.86699C107.867 7.86699 108.021 8.32639 108.021 8.55609C108.04 10.2597 108.04 11.9633 108.04 13.667C108.059 18.5481 111.373 22.2042 116.265 22.6062C117.826 22.7402 119.444 22.7593 121.004 22.5679C122.641 22.3574 123.412 21.1323 122.738 19.9838C122.41 19.4287 121.659 18.8927 121.004 18.7587C119.251 18.3759 117.421 18.2993 115.649 17.9739C114.647 17.7825 113.626 17.4953 112.74 17.0359C111.084 16.1937 110.101 14.8155 110.044 12.8821C109.986 10.8148 110.756 9.11121 112.644 8.21154C113.819 7.65643 115.167 7.33101 116.477 7.15874C118.962 6.81418 121.466 6.94818 123.855 7.80956C124.49 8.03926 125.107 8.38382 125.646 8.78579C126.32 9.30262 126.397 10.03 126.032 10.7957C125.55 11.8485 125.165 11.9442 124.144 11.4082C121.774 10.164 119.213 9.91516 116.593 10.2406C116.073 10.298 115.572 10.5277 115.11 10.7574C114.455 11.102 114.108 11.6571 114.146 12.4419C114.185 13.1884 114.628 13.6861 115.283 13.8967C116.131 14.1647 117.036 14.2986 117.922 14.4518C119.559 14.7198 121.197 14.8729 122.815 15.2175C124.856 15.6577 126.552 16.6339 127.149 18.8353C127.727 21.094 126.667 23.5824 124.491 24.7692C123.566 25.2861 122.487 25.688 121.428 25.8412C117.556 26.358 113.761 26.0134 110.255 24.1184C109.177 23.5059 108.252 22.7211 107.231 21.9745Z"
        fill="#FFFEF3"
      />
      <path
        id="Vector_2"
        d="M38.117 7.31169V3.11963H42.2584V7.29255H47.3053V10.8529H42.4318C42.3548 10.9869 42.297 11.0635 42.297 11.1401C42.2777 14.1836 42.2584 17.208 42.297 20.2516C42.3162 21.4384 43.2216 22.2998 44.5892 22.3955C45.4753 22.4529 46.3806 22.4146 47.3053 22.4146V25.841C45.5138 26.1855 43.7995 26.2238 42.1043 25.6879C39.6773 24.9222 38.194 22.8932 38.1555 20.175C38.117 17.4569 38.1362 14.7196 38.1362 12.0014V11.0252C35.401 11.0252 32.7427 10.7764 30.1423 11.0826C27.1758 11.4463 25.1918 14.3367 25.5385 17.1315C25.9238 20.2899 28.3701 22.4337 31.5292 22.3572C32.4153 22.338 33.3206 22.2423 34.303 22.1466V25.5921C30.6046 26.6449 26.1934 25.6687 23.9782 23.276C21.2815 20.3664 20.3954 16.9975 21.8786 13.2457C23.3426 9.513 26.1934 7.46483 30.2386 7.29255C32.473 7.19684 34.7268 7.27341 36.9805 7.27341C37.2887 7.31169 37.6354 7.31169 38.117 7.31169Z"
        fill="#FFFEF3"
      />
      <path
        id="Vector_3"
        d="M73.7146 23.6971C73.6953 23.8502 73.6761 24.1374 73.6761 24.4245C73.6568 26.6258 73.6568 28.8271 73.6568 31.0284C73.6568 31.8132 73.522 32.6746 72.6744 32.8278C71.7305 32.9809 70.7289 32.8086 69.7465 32.7321C69.6694 32.7129 69.5539 32.3109 69.5539 32.0812C69.5346 26.8747 69.4768 21.6681 69.5731 16.4423C69.6694 12.0589 71.9424 8.90046 75.6408 7.59882C79.7245 6.16318 84.1164 7.13941 86.7361 10.0873C89.5292 13.2074 89.8759 18.3565 87.5259 21.9552C85.3685 25.2667 80.8995 26.8555 77.0663 25.5347C75.9105 25.1327 74.8511 24.3288 73.7146 23.6971ZM84.8099 16.0212C84.7328 14.1453 83.9238 12.1546 81.6123 11.1018C78.588 9.70442 75.14 11.1401 74.0228 14.2028C72.7707 17.5909 74.4466 21.3044 77.6249 22.2232C81.3426 23.2951 84.7906 20.577 84.8099 16.0212Z"
        fill="#FFFEF3"
      />
      <path
        id="Vector_4"
        d="M9.89716 26.0137C5.64012 26.0137 1.99949 23.5635 0.593317 19.7734C-0.793591 16.0408 0.304378 11.8296 3.32861 9.30283C8.70288 4.86192 17.9104 6.96753 19.6826 14.2031C21.0309 19.6203 17.4866 26.0137 9.89716 26.0137ZM9.99347 22.4341C13.1911 22.4341 15.6374 19.8883 15.6759 16.5384C15.6952 13.0738 13.3452 10.5853 10.032 10.5662C6.75735 10.5471 4.33026 13.0929 4.34953 16.5193C4.36879 19.8883 6.79588 22.4341 9.99347 22.4341Z"
        fill="#FFFEF3"
      />
      <path
        id="Vector_5"
        d="M57.6877 26.0135C54.3167 25.8986 51.1384 24.2907 49.3855 20.6346C47.7096 17.1699 48.0564 13.7435 50.3871 10.6617C52.9491 7.29273 57.8803 6.0868 62.1566 7.67557C66.1247 9.13035 68.5133 12.7673 68.3014 17.0168C68.0509 22.2234 63.8517 25.9943 57.6877 26.0135ZM64.0058 16.4808C64.0058 12.997 61.6365 10.5468 58.3233 10.566C55.0487 10.5851 52.7372 13.0544 52.7372 16.4808C52.7372 19.9072 55.1643 22.4339 58.4196 22.4148C61.598 22.3956 64.0058 19.8498 64.0058 16.4808Z"
        fill="#FFFEF3"
      />
      <path
        id="Vector_6"
        d="M133.101 0V26.0329H131.579V0H133.101ZM153.327 16.8448H138.148V16.9597C138.148 21.726 141.249 25.095 145.93 25.095C149.031 25.095 151.786 23.5827 153.038 21.6111V23.4679C151.67 25.1332 148.973 26.454 145.776 26.454C140.363 26.454 136.607 22.5682 136.607 16.9597C136.607 11.3511 140.132 7.46532 145.217 7.46532C150.302 7.46532 153.384 11.4468 153.327 16.8448ZM145.179 8.82439C141.384 8.82439 138.726 11.4468 138.205 15.5432H151.766C151.516 11.6574 148.858 8.82439 145.179 8.82439ZM173.764 7.88644H175.267V25.1524C175.267 30.2441 171.549 33.7662 166.348 33.7662C163.767 33.7662 160.743 32.6177 158.759 30.7801V29.153C160.762 31.1629 163.651 32.4071 166.252 32.4071C170.567 32.4071 173.764 29.4401 173.764 25.1332V20.4435C172.608 23.7359 169.565 25.8415 165.732 25.8415C160.685 25.8415 156.582 21.8026 156.582 16.6725C156.582 11.5425 160.666 7.46532 165.732 7.46532C169.565 7.46532 172.628 9.57092 173.764 12.9016V7.88644ZM166.001 24.425C170.432 24.425 173.783 21.0943 173.783 16.6534C173.783 12.2125 170.432 8.84353 166.001 8.84353C161.571 8.84353 158.2 12.1742 158.2 16.6534C158.2 21.1326 161.629 24.425 166.001 24.425ZM186.632 7.46532C191.312 7.46532 194.221 10.2026 194.221 14.6818V26.0138H192.719V22.5299C191.678 24.7121 189.328 26.454 186.574 26.454C182.509 26.454 179.793 23.9464 179.793 20.5775C179.793 17.2085 182.741 14.7009 186.612 14.7009C189.194 14.7009 191.505 15.6963 192.738 17.0362V14.7201C192.738 11.0448 190.369 8.88181 186.516 8.88181C184.262 8.88181 181.893 10.1069 180.371 12.1742V10.4706C181.893 8.51812 184.359 7.46532 186.632 7.46532ZM186.824 25.1524C190.08 25.1524 192.68 22.3768 192.719 18.9121V18.6633C191.717 17.0745 189.29 15.9643 186.824 15.9643C183.607 15.9643 181.334 17.9168 181.334 20.5583C181.334 23.1999 183.569 25.1524 186.824 25.1524ZM200 0V26.0329H198.497V0H200Z"
        fill="#FFFEF3"
      />
    </g>
  </svg>
</template>
