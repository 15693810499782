<template>
  <div ref="main-nav-avatar" class="relative">
    <button
      type="button"
      class="inline-flex items-center gap-x-1 text-sm font-medium leading-6 text-ol-plum"
      aria-expanded="false"
      @click="toggleNavAccount"
    >
      <AvatarIcon size="medium" class="w-12 h-12" />
    </button>

    <div
      v-if="showNavAccount"
      class="absolute right-0 z-10 mt-8 flex max-w-min"
    >
      <div
        class="w-60 shrink rounded-lg bg-white p-4 text-sm font-medium leading-6 text-ol-plum shadow-lg"
      >
        <MainNavAccount class="whitespace-nowrap" />
      </div>
    </div>
  </div>
</template>

<script>
import MainNavAccount from './MainNavAccount.vue';
import AvatarIcon from '@components/AvatarIcon.vue';

export default {
  components: {
    MainNavAccount,
    AvatarIcon,
  },

  props: {
    hidden: Boolean,
  },

  data() {
    return {
      showNavAccount: false,
      menu: [
        {
          title: 'Account',
          dropdown: 'account',
          element: 'span',
        },
      ],
    };
  },

  methods: {
    toggleNavAccount() {
      this.showNavAccount = !this.showNavAccount;
    },
  },

  mounted() {
    document.addEventListener(
      'click',
      (e) => {
        if (
          this.showNavAccount &&
          !this.$refs['main-nav-avatar'].contains(e.target)
        ) {
          this.toggleNavAccount();
        }
      },
      false
    );
  },
};
</script>
