<template>
  <div class="flex w-108">
    <MainNavDropdownList class="item w-2/3">
      <MainNavDropdownTitle>{{ navItems.title }}</MainNavDropdownTitle>
      <MainNavDropdownListLink :href="item.route" :key="item.name" v-for="item in navItems.items">
        <div class="flex gap-4">
          <keep-alive>
            <component :is="item.icon" />
          </keep-alive>
          <header class="text-base font-bold"> {{ item.name }}</header>


        </div>
      </MainNavDropdownListLink>
    </MainNavDropdownList>
    <DropdownNotSureWhereToStart />
  </div>
</template>

<script>
import MainNavDropdownList from '../partials/MainNavDropdownList.vue';
import MainNavDropdownListLink from '../partials/MainNavDropdownListLink.vue';
import MainNavDropdownTitle from '../partials/MainNavDropdownTitle.vue';
import DropdownNotSureWhereToStart from '../partials/DropdownNotSureWhereToStart.vue';
import Phone from '../../Icon/Phone.vue';
import ChatBubble from '../../Icon/ChatBubble.vue';
import Sparkles from '../../Icon/Sparkles.vue';
import Briefcase from '../../Icon/Briefcase.vue';

import { ref } from 'vue';


export default {
  components: {
    Briefcase,
    ChatBubble,
    Sparkles,
    Phone,
    DropdownNotSureWhereToStart,
    MainNavDropdownTitle,
    MainNavDropdownList,
    MainNavDropdownListLink,
  },

  setup() {
    const navItems = ref({
      title: 'About us',
      items: [
        {
          name: 'Octopus Legacy',
          icon: 'sparkles',
          route: route('about'),
        }, {
          name: 'Guides',
          icon: 'chatBubble',
          route: route('advice.index'),
        },
        {
          name: 'Careers',
          icon: 'briefcase',
          route: `${route('careers')}`,
        }, {
          name: 'Contact Us',
          icon: 'phone',
          route: route('contact'),
        },
      ],
    });
    return { navItems };
  },
};


</script>

