<template>
  <MainNavDropdownList class="w-88">
    <MainNavDropdownTitle>{{ navItems.title }}</MainNavDropdownTitle>
    <MainNavDropdownListLink :href="item.route" :key="item.name" v-for="item in navItems.items">
      <div class="flex gap-4">
        <keep-alive>
          <component class="my-auto" :is="item.icon" />
        </keep-alive>
        <div>
          <header class="font-bold text-base text-ol-dark-ink-200">{{ item.name }}</header>
          <p class=" whitespace-pre-wrap text-dark-pewter-50">{{ item.description }}</p>
        </div>
      </div>
    </MainNavDropdownListLink>
  </MainNavDropdownList>


</template>

<script>
import MainNavDropdownList from '../partials/MainNavDropdownList.vue';
import MainNavDropdownListLink from '../partials/MainNavDropdownListLink.vue';
import MainNavDropdownTitle from '../partials/MainNavDropdownTitle.vue';
import DropdownNotSureWhereToStart from '../partials/DropdownNotSureWhereToStart.vue';
import Pencil from '@components/Icon/Pencil.vue';
import House from '@components/Icon/House.vue';
import Clipboard from '@components/Icon/Clipboard.vue';
import { ref, computed } from 'vue';
import Present from '@components/Icon/Present.vue';

export default {
  components: {
    DropdownNotSureWhereToStart,
    MainNavDropdownTitle,
    MainNavDropdownList,
    MainNavDropdownListLink,
    Pencil,
    House,
    Clipboard,
    Present,
  },


  setup() {
    const willRoute = computed(() => {
      return window.guardianAngel?.user?.hasWill ? route('will-basic.onboarding-welcome') : route('will-writing');
    });

    const navItems = ref({
      title: 'Plan for death',
      items: [
        {
          name: 'Will Writing',
          description: 'Write a will that’s tailored to you',
          icon: 'pencil',
          route: willRoute.value,
        }, {
          name: 'Life Insurance',
          description: 'Life Insurance for the 21st century',
          icon: 'house',
          route: route('life-insurance'),
        },
        {
          name: 'Lasting Power of Attorney',
          description: 'Appoint someone you trust to make \n' +
            'decisions on your behalf',
          icon: 'clipboard',
          route: route('lasting-power-of-attorney'),
        },
        {
          name: 'Legacy Box',
          description: 'Make life easier for the people you leave behind',
          icon: 'present',
          route: route('fwig'),
        },

      ],
    });
    return { navItems };
  },


};
</script>
