<template>
  <svg
    width="70"
    height="34"
    viewBox="0 0 70 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 11858">
      <g id="Group 49">
        <path
          id="Union"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.55487 16.9999C6.55487 11.4385 11.0953 6.89941 16.7342 6.89941C19.0256 6.89941 21.1667 8.05523 23.2424 9.88955L31.9749 18.4524C32.0453 18.5214 32.1182 18.5863 32.1934 18.6469L42.6552 28.4899C42.6673 28.5014 42.6796 28.5127 42.6919 28.5239C42.7002 28.5314 42.7085 28.5389 42.7168 28.5463C45.2879 30.8338 48.8257 33.1004 53.2659 33.1004C62.1844 33.1004 69.4451 25.9089 69.4451 16.9999C69.4451 8.09096 62.1844 0.899467 53.2659 0.899467C48.4518 0.899467 44.0702 3.45157 41.2615 6.4157L41.2496 6.42832L37.897 10.0077C36.7644 11.217 36.8265 13.1154 38.0357 14.2481C39.245 15.3807 41.1435 15.3186 42.2761 14.1094L45.6224 10.5368C47.6204 8.43097 50.5183 6.89947 53.2659 6.89947C58.9047 6.89947 63.4451 11.4385 63.4451 16.9999C63.4451 22.5613 58.9047 27.1004 53.2659 27.1004C50.9662 27.1004 48.8179 25.9363 46.7352 24.0904L35.8995 13.8957C35.8884 13.8853 35.8774 13.875 35.8663 13.8649L27.3896 5.55285C27.3549 5.51888 27.3195 5.48576 27.2832 5.45351C24.7121 3.16605 21.1743 0.899414 16.7342 0.899414C7.81558 0.899414 0.554871 8.0909 0.554871 16.9999C0.554871 25.9089 7.81558 33.1004 16.7342 33.1004C21.5482 33.1004 25.9298 30.5483 28.7385 27.5841L28.7504 27.5715L32.103 23.9921C33.2356 22.7829 33.1735 20.8844 31.9643 19.7518C30.755 18.6191 28.8566 18.6812 27.7239 19.8905L24.3775 23.4632C22.3795 25.5689 19.4816 27.1004 16.7342 27.1004C11.0953 27.1004 6.55487 22.5613 6.55487 16.9999Z"
          fill="#D5CAFE"
        />
      </g>
      <g id="Group 50">
        <g id="Group 51">
          <path
            id="Ellipse 11 (Stroke)"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.674 5.35248C10.2341 5.35248 5.01355 10.5673 5.01355 17.0002C5.01355 23.433 10.2341 28.6478 16.674 28.6478C19.9288 28.6478 23.1927 26.8567 25.3919 24.5259C25.3929 24.5249 25.3939 24.5238 25.3949 24.5227L28.7407 20.933C29.3111 20.3211 30.2701 20.2869 30.8827 20.8566C31.4953 21.4263 31.5295 22.3843 30.9592 22.9962L27.6074 26.5923L27.6014 26.5987C24.9949 29.3629 20.9798 31.6756 16.674 31.6756C8.56007 31.6756 1.98245 25.1052 1.98245 17.0002C1.98245 8.89513 8.56007 2.32471 16.674 2.32471C20.5896 2.32471 23.7864 4.33224 26.2375 6.5237C26.2558 6.54003 26.2737 6.5568 26.2911 6.574L35.0751 15.2297C35.671 15.8169 35.6775 16.7754 35.0897 17.3706C34.5019 17.9658 33.5423 17.9724 32.9464 17.3852L24.1891 8.75572C21.9848 6.7913 19.496 5.35248 16.674 5.35248Z"
            fill="#4A25B5"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
