import axios from 'axios';

export async function logout() {
  try {
    await axios.post(route('logout'), null, {
      withCredentials: true,
    });
  } catch (error) {
    console.error(error);
  }
}
