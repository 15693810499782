<template>
  <div class="nav">
    <div v-if="modelValue === null">
      <ul class="nav__items">
        <li
          @click="openMenu(index)"
          class="nav__items__item"
          v-for="(menu, index) in menus"
        >
          {{ menu.title }}
          <ChevronRight class="nav__chevron"></ChevronRight>
        </li>
      </ul>

      <DropdownNotSureWhereToStart class="nav__where-to-start" />
    </div>
    <transition>
      <MobileMenuItem
        v-if="modelValue !== null"
        :navItems="menus[modelValue]"
      ></MobileMenuItem>
    </transition>
    <div class="nav__button-group">
      <MainNavCTA></MainNavCTA>
      <ButtonRounded
        v-if="!isAuthenticated"
        variant="tertiary"
        :size="buttonSize"
        :width="buttonWidth"
        ><a :href="route('login')">Sign in </a>
      </ButtonRounded>
    </div>
    <MainNavAccount v-if="isAuthenticated" class="mt-6" />
  </div>
</template>

<script>
import RegisterButton from './partials/RegisterButton.vue';
import MainNavAccount from './MainNavAccount.vue';
import { mapGetters } from 'vuex';
import { computed, ref } from 'vue';
import MobileMenuItem from './MobileMenuItem.vue';
import DropdownNotSureWhereToStart from './partials/DropdownNotSureWhereToStart.vue';
import Arrow from '../Icon/Arrow.vue';
import ChevronRight from '../Icon/ChevronRight.vue';
import ButtonRounded from '../Button/ButtonRounded.vue';
import { useScreenSize } from '../../composables/useScreenSizeHelper';
import MainNavCTA from './partials/MainNavCTA.vue';
import { ScreenSizes } from '@enums';

export default {
  components: {
    ButtonRounded,
    ChevronRight,
    Arrow,
    DropdownNotSureWhereToStart,
    MobileMenuItem,
    MainNavAccount,
    RegisterButton,
    MainNavCTA,
  },
  props: {
    modelValue: [Number],
  },
  setup(props, { emit }) {
    const open = ref(false);
    let { screenWidth } = useScreenSize();
    const showMenuScreen = ref(false);
    // prettier-ignore
    const buttonSize = computed(() => {
      return screenWidth.value >= ScreenSizes.lg
        ? 'xs'
        : screenWidth.value > ScreenSizes.sm
          ? 'large'
          : 'medium';
    });

    const buttonWidth = computed(() => {
      return screenWidth.value > ScreenSizes.sm ? '1/2' : 'full';
    });

    const willRoute = computed(() => {
      return window.guardianAngel?.user?.hasWill
        ? route('will-basic.onboarding-welcome')
        : route('will-writing');
    });

    const menus = ref([
      {
        title: 'About us',
        itemsClass: 'font-medium',
        items: [
          {
            name: 'Octopus Legacy',
            icon: 'sparkles',
            route: route('about'),
          },
          {
            name: 'Guides',
            icon: 'chatBubble',
            route: route('advice.index'),
          },
          {
            name: 'Careers',
            icon: 'briefcase',
            route: `${route('careers')}`,
          },
          {
            name: 'Contact Us',
            icon: 'phone',
            route: route('contact'),
          },
        ],
      },
      {
        title: 'Plan for Death',
        itemsClass: 'font-bold',
        items: [
          {
            name: 'Will Writing',
            description: 'Write a will that’s tailored to you',
            icon: 'pencil',
            route: willRoute.value,
          },
          {
            name: 'Life Insurance',
            description: 'Life Insurance for the 21st century',
            icon: 'house',
            route: route('life-insurance'),
          },
          {
            name: 'Lasting Power of Attorney',
            description:
              'Appoint someone you trust to make \n' +
              'decisions on your behalf',
            icon: 'clipboard',
            route: route('lasting-power-of-attorney'),
          },
          {
            name: 'Legacy Box',
            description: 'Make life easier for the people you leave behind',
            icon: 'present',
            route: route('fwig'),
          },
        ],
      },
      {
        title: 'Get support after loss',
        itemsClass: 'font-bold',

        items: [
          {
            name: 'Bereavement Support Hubs',
            description: 'Build a supportive community',
            icon: 'gift',
            route: route('bereavement'),
          },
          {
            name: 'Probate',
            description: 'Your guide through probate',
            icon: 'scale',
            route: route('probate'),
          },
        ],
      },
    ]);

    const openMenu = (i) => {
      emit('update:modelValue', i);
    };

    return { open, menus, openMenu, showMenuScreen, buttonSize, buttonWidth };
  },

  computed: {
    ...mapGetters('user', ['isAuthenticated']),
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.nav__button-group {
  @apply p-10 flex flex-col sm:flex-row sm:px-10 bg-white gap-2 mt-auto;
}

.nav {
  @apply bg-white w-full flex flex-col h-full;
}

.nav__items {
  @apply p-5 flex flex-col gap-8;
}

.nav__items__item {
  @apply text-ol-dark-ink-200 text-2xl cursor-pointer flex;
}

.nav__where-to-start {
  @apply w-full sm:m-10 text-center sm:w-72 sm:h-72;
}

.nav__chevron {
  @apply my-auto fill-ol-dark-ink-200;
}
</style>
