<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4162 0C23.967 0 24.48 0.512401 24.48 7.0638V16.9356C24.48 23.487 23.967 24 17.4162 24H7.54378C0.992981 24 0.47998 23.487 0.47998 16.9362V7.0638C0.47998 0.512401 0.992981 0 7.54378 0H17.4162ZM8.29738 18.6V8.8368H5.26318V18.6H8.29738ZM6.78058 7.4406C7.75558 7.4406 8.54878 6.6474 8.54878 5.6712C8.54878 4.6962 7.75558 3.903 6.78058 3.903C5.80318 3.903 5.01118 4.695 5.01118 5.6712C5.01118 6.6474 5.80198 7.4406 6.78058 7.4406ZM19.68 18.6V13.245C19.68 10.6158 19.1124 8.5938 16.0398 8.5938C14.5638 8.5938 13.5744 9.4032 13.1688 10.1706H13.128V8.8368H10.2174V18.6H13.2498V13.77C13.2498 12.4968 13.4928 11.2632 15.0714 11.2632C16.6278 11.2632 16.6482 12.7194 16.6482 13.8522V18.6H19.68Z"
      fill="white"
    />
  </svg>
</template>
