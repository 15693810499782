import { deepFreeze } from '../../utils/index';

const Executor = deepFreeze({
  INTRO: {
    STEP: 1,
    TITLE: 'Executors',
    ROUTE_NAME: 'WillExecutorIntro',
  },
  PRICE: {
    STEP: 2,
    TITLE: 'Executors',
    ROUTE_NAME: 'WillExecutorPrice',
  },
  CHOOSE: {
    STEP: 3,
    TITLE: 'Executors',
    ROUTE_NAME: 'WillExecutorChoose',
  },
  CHOOSE_PERSONS: {
    STEP: 4,
    TITLE: 'Executors',
    ROUTE_NAME: 'WillExecutorChooseFriendsFamily',
  },
  TERMS: {
    STEP: 5,
    TITLE: 'Executors',
    ROUTE_NAME: 'WillExecutorTerms',
  },
});

export default Executor;
