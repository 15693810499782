<template>
  <span
    class="cursor-pointer text-ol-cream transition-colors duration-300 ease-in-out"
  >
    <svg
      class="fill-current nav-burger-button"
      width="30"
      height="21"
      viewBox="0 0 30 21"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="top"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 1.45419C0 0.651069 0.651229 0 1.45455 0H27.6364C28.4397 0 29.0909 0.651069 29.0909 1.45419C29.0909 2.25731 28.4397 2.90837 27.6364 2.90837H1.45455C0.651229 2.90837 0 2.25731 0 1.45419Z"
      />
      <path
        id="middle"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.72727 10.1793C8.72727 9.37616 9.3785 8.72512 10.1818 8.72512H27.6364C28.4397 8.72512 29.0909 9.37616 29.0909 10.1793C29.0909 10.9825 28.4397 11.6335 27.6364 11.6335H10.1818C9.3785 11.6335 8.72727 10.9825 8.72727 10.1793Z"
      />
      <path
        id="bottom"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 18.9044C0 18.1013 0.651229 17.4502 1.45455 17.4502H27.6364C28.4397 17.4502 29.0909 18.1013 29.0909 18.9044C29.0909 19.7076 28.4397 20.3586 27.6364 20.3586H1.45455C0.651229 20.3586 0 19.7076 0 18.9044Z"
      />
    </svg>
  </span>
</template>

<script>
import gsap from 'gsap';

export default {
  props: {
    open: {
      required: true,
      type: Boolean,
    },
  },

  watch: {
    open(state) {
      const tl = gsap.timeline();
      tl.set('.nav-burger-button path', { transformOrigin: 'center' });

      if (state) {
        // Open

        tl.to(
          '.nav-burger-button #middle',
          {
            duration: 0.15,
            ease: 'power2.inOut',
            x: -5,
          },
          'align'
        );
        tl.to(
          '.nav-burger-button #top',
          {
            duration: 0.15,
            ease: 'power2.inOut',
            y: 9,
          },
          'align'
        );
        tl.to(
          '.nav-burger-button #bottom',
          {
            duration: 0.15,
            ease: 'power2.inOut',
            y: -9,
          },
          'align'
        );

        tl.to(
          '.nav-burger-button #middle',
          {
            duration: 0.25,
            delay: -0.1,
            ease: 'power2.inOut',
            opacity: 0,
          },
          'align'
        );
        tl.to(
          '.nav-burger-button #top',
          {
            duration: 0.25,
            delay: -0.1,
            ease: 'power2.inOut',
            rotation: 45,
          },
          'rotate'
        );
        tl.to(
          '.nav-burger-button #bottom',
          {
            duration: 0.25,
            delay: -0.1,
            ease: 'power2.inOut',
            rotation: -45,
          },
          'rotate'
        );
      } else {
        // Close

        tl.to(
          '.nav-burger-button',
          {
            duration: 0.25,
            ease: 'power2.inOut',
            rotation: -90,
          },
          'spin'
        );

        tl.set(
          '.nav-burger-button',
          {
            rotation: 0,
          },
          'rotate'
        );
        tl.to(
          '.nav-burger-button #top',
          {
            duration: 0.15,
            ease: 'power2.inOut',
            rotation: 0,
          },
          'rotate'
        );
        tl.to(
          '.nav-burger-button #bottom',
          {
            duration: 0.15,
            ease: 'power2.inOut',
            rotation: 0,
          },
          'rotate'
        );

        tl.to(
          '.nav-burger-button #middle',
          {
            duration: 0.25,
            delay: -0.1,
            ease: 'power2.inOut',
            opacity: 1,
            x: 0,
          },
          'align'
        );
        tl.to(
          '.nav-burger-button #top',
          {
            duration: 0.25,
            delay: -0.1,
            ease: 'power2.inOut',
            y: 0,
          },
          'align'
        );
        tl.to(
          '.nav-burger-button #bottom',
          {
            duration: 0.25,
            delay: -0.1,
            ease: 'power2.inOut',
            y: 0,
          },
          'align'
        );
      }
    },
  },
};
</script>
