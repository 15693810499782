import { deepFreeze } from '@js/utils';

const LegacyBox = deepFreeze({
  PRICE: 69.99,
  PRODUCT_NAME: 'Legacy Box',
  standardDelivery: {
    SHIPPING_COST: 0.0,
  },
});

export default LegacyBox;
