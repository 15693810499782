<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="heroicons-mini/chevron-down">
      <path
        id="Vector 4 (Stroke)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.82951 12.3295C8.26884 11.8902 8.98116 11.8902 9.4205 12.3295L15 17.909L20.5795 12.3295C21.0188 11.8902 21.7312 11.8902 22.1705 12.3295C22.6098 12.7688 22.6098 13.4812 22.1705 13.9205L15.7955 20.2955C15.5845 20.5065 15.2984 20.625 15 20.625C14.7016 20.625 14.4155 20.5065 14.2045 20.2955L7.8295 13.9205C7.39016 13.4812 7.39017 12.7688 7.82951 12.3295Z"
        fill="#160535"
      />
    </g>
  </svg>
</template>
