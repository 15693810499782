<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="heroicons-outline/briefcase">
      <path
        id="Vector"
        d="M16.875 11.7916V15.3333C16.875 16.2454 16.2193 17.0299 15.3151 17.15C13.5762 17.3808 11.802 17.5 10 17.5C8.19798 17.5 6.42378 17.3808 4.68487 17.15C3.78075 17.0299 3.125 16.2454 3.125 15.3333V11.7916M16.875 11.7916C17.2683 11.4564 17.5 10.9491 17.5 10.4074V7.25474C17.5 6.35396 16.8601 5.57568 15.9692 5.44238C15.0314 5.30206 14.0829 5.19429 13.125 5.12027M16.875 11.7916C16.7137 11.9291 16.5251 12.0377 16.3143 12.1078C14.3294 12.7677 12.2064 13.125 10 13.125C7.79357 13.125 5.67058 12.7677 3.68574 12.1078C3.47487 12.0377 3.28634 11.9291 3.125 11.7916M3.125 11.7916C2.73173 11.4563 2.5 10.9491 2.5 10.4074V7.25474C2.5 6.35396 3.13992 5.57568 4.03078 5.44238C4.96861 5.30206 5.91708 5.19429 6.875 5.12027M13.125 5.12027V4.375C13.125 3.33947 12.2855 2.5 11.25 2.5H8.75C7.71447 2.5 6.875 3.33947 6.875 4.375V5.12027M13.125 5.12027C12.0938 5.04058 11.0516 5 10 5C8.94836 5 7.9062 5.04058 6.875 5.12027M10 10.625H10.0062V10.6312H10V10.625Z"
        stroke="#0F172A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
