import { ref, onMounted, onUnmounted } from 'vue';

export function useScreenSize() {
  const screenWidth = ref(window.innerWidth);
  const screenHeight = ref(window.innerHeight);

  function update(event) {
    screenWidth.value = event.target.innerWidth;
    screenHeight.value = event.target.innerHeight;
  }

  onMounted(() => window.addEventListener('resize', update));
  onUnmounted(() => window.removeEventListener('resize', update));

  return { screenHeight, screenWidth };
}
