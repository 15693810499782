<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="heroicons-outline/scale">
      <path
        id="Vector"
        d="M10 2.5V16.875M10 16.875C8.77333 16.875 7.59825 17.0959 6.51236 17.5M10 16.875C11.2267 16.875 12.4017 17.0959 13.4876 17.5M15.625 4.14241C13.7871 3.88376 11.9092 3.75 10 3.75C8.09081 3.75 6.21285 3.88376 4.375 4.14241M15.625 4.14241C16.467 4.26092 17.3007 4.40564 18.125 4.57573M15.625 4.14241L17.8086 13.0801C17.9102 13.4957 17.7202 13.9364 17.3176 14.0812C16.789 14.2714 16.2191 14.375 15.625 14.375C15.0309 14.375 14.461 14.2714 13.9324 14.0812C13.5298 13.9364 13.3398 13.4957 13.4414 13.0801L15.625 4.14241ZM1.875 4.57573C2.69935 4.40564 3.53297 4.26092 4.375 4.14241M4.375 4.14241L6.55864 13.0801C6.66017 13.4957 6.47016 13.9364 6.06761 14.0812C5.539 14.2714 4.9691 14.375 4.375 14.375C3.7809 14.375 3.211 14.2714 2.68239 14.0812C2.27984 13.9364 2.08983 13.4957 2.19136 13.0801L4.375 4.14241Z"
        stroke="#0F172A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
