<template>
  <nav
    class="navbar"
    :class="[{ 'h-full-height rounded-none lg:h-auto': statusMobileNav }]"
  >
    <div class="navbar__items">
      <span v-if="showOctopusLogo" class="navbar__logo">
        <a :href="appRoute('home')" class="flex lg:block">
          <LogoOctopusLegacy class="h-7"></LogoOctopusLegacy>
        </a>
      </span>
      <button v-else @click="mobileItemIndex = null">
        <span class="flex">
          <BackArrow class="stroke-white p-1"></BackArrow>Back</span
        >
      </button>

      <ul class="navbar__menu">
        <MenuItem
          v-model="desktopItemId"
          v-for="item in menu"
          :title="item.title"
          :id="item.id"
          :content="item.content"
          :key="item.id"
          class="my-auto"
        ></MenuItem>
      </ul>

      <BurgerIcon
        class="navbar__burger-icon"
        :open="statusMobileNav"
        @click.native="toggleMobileNav"
      ></BurgerIcon>

      <div class="navbar__right">
        <MainNavCTA />
        <a
          v-if="!isAuthenticated"
          :href="appRoute('login')"
          class="navbar__sign-in"
          >Sign in</a
        >
        <MainNavAvatar v-else />
      </div>
    </div>
    <div class="navbar__mobile">
      <MobileNav v-model="mobileItemIndex" v-if="statusMobileNav" />
    </div>
  </nav>
</template>

<script>
import MainNavCTA from '@components/MainNav/partials/MainNavCTA.vue';
import MainNavAvatar from '@components/MainNav/MainNavAvatar.vue';
import MobileNav from '@components/MainNav/MobileNav.vue';
import { mapGetters } from 'vuex';
import SubNav from '@components/MainNav/MainNavDropdown.vue';
import MenuItem from '@components/MainNav/MenuItem.vue';
import BurgerIcon from '@components/MainNav/partials/BurgerIcon.vue';
import { useScreenSize } from '@js/composables/useScreenSizeHelper';
import Briefcase from '@components/Icon/Briefcase.vue';
import BackArrow from '@components/Icon/BackArrow.vue';
import { ScreenSizes } from '@enums';
import LogoOctopusLegacy from '@components/Icon/LogoOctopusLegacy.vue';

export default {
  components: {
    BackArrow,
    Briefcase,
    BurgerIcon,
    MenuItem,
    SubNav,
    MobileNav,
    MainNavAvatar,
    MainNavCTA,
    LogoOctopusLegacy,
  },

  props: {
    customClasses: {
      type: String,
      required: false,
    },
  },

  setup() {
    const { screenWidth } = useScreenSize();
    return { screenWidth };
  },

  data() {
    return {
      desktopItemId: null,
      mobileItemIndex: null,
      statusMobileNav: false,
      menu: [
        {
          id: 1,
          title: 'About Us',
          dropdown: 'about',
          content: 'AboutNavContent',
        },
        {
          id: 2,
          title: 'Plan for death',
          dropdown: 'eol',
          content: 'EndOfLifeNavContent',
        },
        {
          id: 3,
          title: 'Get support after loss',
          dropdown: 'bereavement',
          content: 'BereavementContent',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('user', ['isAuthenticated']),
    showOctopusLogo() {
      return (
        this.mobileItemIndex === null ||
        !this.statusMobileNav ||
        this.screenWidth > ScreenSizes.lg
      );
    },
  },

  methods: {
    toggleMobileNav() {
      this.statusMobileNav = !this.statusMobileNav;
      if (this.statusMobileNav === false) {
        this.mobileItemIndex = null;
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  @apply z-30 sticky top-0 py-5 lg:py-6 text-white bg-ol-dark-ink-200 my-auto align-middle border-b border-ol-dark-100;
}

.navbar__items {
  @apply flex justify-between my-auto max-w-screen-2xl 2xl:mx-auto px-5 xl:px-10;
}

.navbar__logo {
  @apply justify-start lg:justify-center my-auto;
}

.navbar__mobile {
  @apply lg:hidden translate-y-4 overflow-y-auto lg:overflow-y-auto lg:h-auto;
}

.navbar__sign-in {
  @apply text-xs lg:text-base font-medium text-ol-pink hover:underline;
}

.navbar__burger-icon {
  @apply lg:hidden flex justify-end items-center;
}

.navbar__right {
  @apply hidden lg:flex lg:gap-x-4 items-center;
}

.navbar__menu {
  @apply hidden lg:flex lg:gap-x-8;
}
</style>
