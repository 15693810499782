<template>
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.2288 7.23182C19.6631 7.65607 19.6631 8.34393 19.2288 8.76818L12.1501 15.6818C11.7157 16.1061 11.0115 16.1061 10.5771 15.6818C10.1427 15.2576 10.1427 14.5697 10.5771 14.1454L15.7569 9.08637L0.645447 9.08637V6.91363L15.7569 6.91363L10.5771 1.85455C10.1427 1.4303 10.1427 0.742445 10.5771 0.318191C11.0115 -0.106064 11.7157 -0.106064 12.1501 0.318191L19.2288 7.23182Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'Arrow',
};
</script>
