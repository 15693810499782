<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 10H10.625M7.5 12.5H10.625M7.5 15H10.625M13.125 15.625H15C16.0355 15.625 16.875 14.7855 16.875 13.75V5.09018C16.875 4.14436 16.1708 3.34179 15.2283 3.26356C14.9172 3.23774 14.6051 3.21548 14.2921 3.19683M9.45794 3.19683C9.40402 3.37168 9.375 3.55745 9.375 3.75C9.375 4.09518 9.65482 4.375 10 4.375H13.75C14.0952 4.375 14.375 4.09518 14.375 3.75C14.375 3.55745 14.346 3.37168 14.2921 3.19683M9.45794 3.19683C9.69396 2.43131 10.407 1.875 11.25 1.875H12.5C13.343 1.875 14.056 2.43131 14.2921 3.19683M9.45794 3.19683C9.14489 3.21548 8.83281 3.23774 8.52174 3.26356C7.57916 3.34179 6.875 4.14437 6.875 5.09018V6.875M6.875 6.875H4.0625C3.54473 6.875 3.125 7.29473 3.125 7.8125V17.1875C3.125 17.7053 3.54473 18.125 4.0625 18.125H12.1875C12.7053 18.125 13.125 17.7053 13.125 17.1875V7.8125C13.125 7.29473 12.7053 6.875 12.1875 6.875H6.875ZM5.625 10H5.63125V10.0063H5.625V10ZM5.625 12.5H5.63125V12.5063H5.625V12.5ZM5.625 15H5.63125V15.0063H5.625V15Z"
      stroke="#0F172A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
