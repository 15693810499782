<template>
  <a :href="buttonDetail.route" class="button-cta">
    {{ buttonDetail.label }}
  </a>
</template>

<script>
import { useCtaDetails } from '@js/composables/useCtaDetails';

export default {
  setup() {
    const { labelOnlineWill, labelSupportHubs, buttonDetail, bowCtaLabel } =
      useCtaDetails();
    return { labelOnlineWill, labelSupportHubs, buttonDetail, bowCtaLabel };
  },
};
</script>

<style scoped>
.button-cta {
  @apply bg-ol-pink text-ol-dark-ink-200 w-full sm:w-1/2 text-center lg:bg-ol-dark-ink-200
  lg:text-white  lg:hover:bg-ol-accent-1-100  lg:hover:border-ol-accent-1-100 transition-colors
  duration-150 ease-in-out rounded-full lg:border xl:border-3/2 py-4  lg:py-2 lg:w-auto lg:px-4
  font-medium text-base whitespace-nowrap flex items-center justify-center;
}
</style>
