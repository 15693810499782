<template>
  <div>
    <div v-if="displayAvatar" :class="[elementClassList]">
      <img
        :src="avatar"
        class="rounded-full object-cover w-full h-full"
        alt="user avatar"
      />
    </div>
    <div
      v-else
      :class="[elementClassList]"
      class="bg-ol-accent-2-10 rounded-full flex justify-center items-center w-full h-full"
    >
      <span
        class="text-ol-accent-2-100 text-center font-medium"
        :class="[fontSizeClass]"
        >{{ firstInitialName }}</span
      >
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('user');

export default {
  props: {
    size: {
      type: String,
      default: 'medium',
    },
  },

  data() {
    return {
      elementClassList: '',
    };
  },

  computed: {
    ...mapState(['avatar', 'name', 'email']),

    fontSizeClass() {
      switch (this.size) {
        case 'large':
          return 'text-5xl';

        case 'small':
          return 'text-base';

        case 'medium':
        default:
          return 'text-2xl';
      }
    },

    firstInitialName() {
      return this.name ? this.name.charAt(0) : this.email.charAt(0);
    },

    displayAvatar() {
      return (
        this.avatar?.length > 0 && !this.avatar.includes('default-avatar.svg')
      );
    },
  },

  mounted() {
    this.elementClassList = this.$el.classList.value;
  },
};
</script>
