<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8124 0C1.78315 0 0.920166 0.863587 0.920166 11.8922V12.1078C0.920166 23.1364 1.78315 24 12.8124 24H13.028C24.0572 24 24.9202 23.1364 24.9202 12.1078V12C24.9202 0.8712 24.049 0 12.9202 0H12.8124ZM20.1167 3.6C20.7797 3.5982 21.3184 4.13348 21.3202 4.79648C21.322 5.45948 20.7867 5.9982 20.1237 6C19.4607 6.0018 18.922 5.46652 18.9202 4.80352C18.9184 4.14052 19.4537 3.6018 20.1167 3.6ZM12.9061 6C16.2193 5.9922 18.9124 8.67274 18.9202 11.9859C18.928 15.2991 16.2474 17.9922 12.9342 18C9.62103 18.0078 6.92797 15.3273 6.92017 12.0141C6.91237 8.70086 9.5929 6.0078 12.9061 6ZM12.912 8.4C10.9236 8.4048 9.31537 10.021 9.32017 12.0094C9.32497 13.9972 10.9406 15.6048 12.9284 15.6C14.9168 15.5952 16.525 13.9796 16.5202 11.9918C16.5154 10.0034 14.8998 8.3952 12.912 8.4Z"
      fill="white"
    />
  </svg>
</template>
