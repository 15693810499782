<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="heroicons-outline/gift-top">
      <path
        id="Vector"
        d="M10 3.125V16.875M1.875 10H18.125M5.3125 14.375C7.55616 14.375 9.375 12.5562 9.375 10.3125V10M14.6875 14.375C12.4438 14.375 10.625 12.5562 10.625 10.3125V10M3.125 16.875H16.875C17.5654 16.875 18.125 16.3154 18.125 15.625V4.375C18.125 3.68464 17.5654 3.125 16.875 3.125H3.125C2.43464 3.125 1.875 3.68464 1.875 4.375V15.625C1.875 16.3154 2.43464 16.875 3.125 16.875ZM13.6427 9.00891C12.4685 10.1831 10.1072 9.89279 10.1072 9.89279C10.1072 9.89279 9.81689 7.53143 10.9911 6.35726C11.7233 5.62503 12.9105 5.62503 13.6427 6.35726C14.375 7.08949 14.375 8.27668 13.6427 9.00891ZM8.97748 6.35728C10.1517 7.53146 9.86136 9.89281 9.86136 9.89281C9.86136 9.89281 7.5 10.1831 6.32583 9.00893C5.59359 8.2767 5.59359 7.08951 6.32583 6.35728C7.05806 5.62505 8.24524 5.62505 8.97748 6.35728Z"
        stroke="#0F172A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
