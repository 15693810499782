import { computed, ref } from 'vue';

export function useCtaDetails() {
  const hasWill = ref(window.guardianAngel?.user?.hasWill);
  const hasBowScore = ref(window.guardianAngel?.user?.has_bow_score);
  const hasNetworks = ref(window.guardianAngel?.user?.has_networks);
  const currentRoute = computed(() => route().current());
  const labelOnlineWill = computed(() =>
    hasWill.value ? 'Continue your will' : "Let's get started"
  );
  const labelSupportHubs = computed(() =>
    hasNetworks.value ? 'View my support hubs' : 'Create a support hub'
  );
  const bowCtaLabel = computed(() =>
    hasBowScore.value ? 'View my score' : 'Take the quiz'
  );

  const buttonDetail = computed(() => {
    switch (currentRoute.value) {
      case 'life-insurance':
        return {
          label: 'Get a quote',
          route: route('life.welcome'),
        };

      case 'online-will':
        return {
          label: labelOnlineWill.value,
          route: route('will-basic.onboarding-welcome'),
        };

      case 'will-writing':
        return {
          label: labelOnlineWill.value,
          route: route('will-recommendation.onboarding-welcome'),
        };

      case 'lasting-power-of-attorney':
        return {
          label: 'Start my lpa',
          route: route('lpa.typeform-onboarding.welcome'),
        };

      case 'bereavement':
        return {
          label: labelSupportHubs.value,
          route: route('support-hubs.index'),
        };

      case 'probate':
        return {
          label: 'Contact us',
          route: route('contact'),
        };

      case 'bow.onboarding2.promoted':
        return {
          label: 'Contact us',
          route: route('contact'),
        };

      default:
        return {
          label: bowCtaLabel.value,
          route: route('bow'),
        };
    }
  });

  const sectionCta = computed(() => {
    switch (currentRoute.value) {
      case 'bereavement':
        return {
          heading: 'Create a support hub today.',
          subheading:
            'Create a community that will support you before, during and after the funeral has been and gone.',
          route: route('support-hubs.index'),
          buttonLabel: labelSupportHubs.value,
        };
      case 'probate':
        return {
          heading: 'We’re here to make probate simple.',
          subheading:
            'Book a free call with our team of experts to find out more about the probate process, whether you might need probate, and how we might be able to help.',
          route: route('contact'),
          buttonLabel: 'Get in touch',
        };
      case 'funeral-directors':
        return {
          heading: 'Want to make life easier for your families?',
          subheading: `Book in a quick, zero-obligation call with our team today.
            You can talk through your challenges and we can see how we might be able to help.`,
          route: 'https://meetings.hubspot.com/dylan151/inbound',
          buttonLabel: 'Book a call',
        };
      case 'charities': {
        return {
          heading: 'Want help reaching your legacy goals?',
          subheading: `Book in a quick, zero-obligation call with our team today.
            You can talk through your challenges and we can see how we might be able to help.`,
          route: 'https://meetings.hubspot.com/dylan151/charity',
          buttonLabel: 'Book a call',
        };
      }
      case 'fwig':
        return {
          heading: 'Give a gift that connects you to the people you love.',
          subheading: "While they're here and after they're gone.",
          route: route('fwig.product'),
          buttonLabel: 'Buy now',
        };
      case 'contact':
        return {
          heading: 'Unsure where to start planning ahead?',
          subheading:
            "Take this free 1-minute quiz. You'll find out how prepared you are, and how to improve.",
          route: route('bow'),
          buttonLabel: 'Get Started',
        };
      default:
        return {
          heading: 'Unsure where to start planning ahead?',
          subheading:
            'Take this free 1-minute quiz. You’ll find out how prepared you are, and how to improve.',
          route: route('bow'),
          buttonLabel: bowCtaLabel.value,
        };
    }
  });
  return {
    labelOnlineWill,
    labelSupportHubs,
    buttonDetail,
    bowCtaLabel,
    sectionCta,
  };
}
