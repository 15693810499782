<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="heroicons-mini/chevron-down">
      <path
        id="Vector 4 (Stroke)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3295 22.1705C11.8902 21.7312 11.8902 21.0188 12.3295 20.5795L17.909 15L12.3295 9.42049C11.8902 8.98116 11.8902 8.26884 12.3295 7.82951C12.7688 7.39017 13.4812 7.39017 13.9205 7.82951L20.2955 14.2045C20.5065 14.4155 20.625 14.7016 20.625 15C20.625 15.2984 20.5065 15.5845 20.2955 15.7955L13.9205 22.1705C13.4812 22.6098 12.7688 22.6098 12.3295 22.1705Z"
      />
    </g>
  </svg>
</template>
