<template>
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 2.84134C29.1816 4.05527 28.1455 5.12216 26.952 5.97739C26.9632 6.23765 26.9694 6.49853 26.9694 6.76125C26.9694 14.7678 20.83 24 9.59909 24C6.15057 24 1.65672 22.8014 0.23999 21.2786C0.71864 21.3352 1.20473 21.3636 1.69702 21.3636C4.55838 21.3636 7.19095 20.3945 9.27978 18.7696C6.60814 18.7209 4.35316 16.968 3.57628 14.5611C4.7915 14.7506 6.33348 14.4565 6.33348 14.4565C6.33348 14.4565 1.55938 13.6431 1.436 8.43972C2.81614 9.23404 4.20125 9.19712 4.20125 9.19712C4.20125 9.19712 1.44778 7.51496 1.48622 4.15495C1.50916 2.17315 2.31145 1.10749 2.31145 1.10749C7.52824 7.48235 14.8965 7.43867 14.8965 7.43867C14.8965 7.43867 14.7384 6.5336 14.7384 6.05861C14.7384 2.71336 17.4714 0 20.8436 0C22.5995 0 24.1861 0.735868 25.2997 1.91412C26.6916 1.64155 27.9986 1.13703 29.1779 0.442997C28.6453 2.55954 26.4938 3.79501 26.4938 3.79501C26.4938 3.79501 28.3346 3.59259 30 2.84134Z"
      fill="white"
    />
  </svg>
</template>
