<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="heroicons-outline/home-modern">
      <path
        id="Vector"
        d="M6.875 17.5V13.4375C6.875 12.9197 7.29473 12.5 7.8125 12.5H9.6875C10.2053 12.5 10.625 12.9197 10.625 13.4375V17.5M10.625 17.5H14.375V2.95455M10.625 17.5H16.875V8.95833M1.875 17.5H3.125M18.125 17.5H3.125M1.875 7.5L5.625 6.13636M15.625 2.5L14.375 2.95455M14.375 8.125L16.875 8.95833M18.125 9.375L16.875 8.95833M5.625 6.13636V2.5H3.125V17.5M5.625 6.13636L14.375 2.95455"
        stroke="#0F172A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
