<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.5 9.375V16.25C17.5 16.9404 16.9404 17.5 16.25 17.5H4.375C3.68464 17.5 3.125 16.9404 3.125 16.25V9.375M10 4.0625C10 2.85438 9.02062 1.875 7.8125 1.875C6.60438 1.875 5.625 2.85438 5.625 4.0625C5.625 5.27062 6.60438 6.25 7.8125 6.25C8.42459 6.25 10 6.25 10 6.25M10 4.0625C10 4.65853 10 6.25 10 6.25M10 4.0625C10 2.85438 10.9794 1.875 12.1875 1.875C13.3956 1.875 14.375 2.85438 14.375 4.0625C14.375 5.27062 13.3956 6.25 12.1875 6.25C11.5754 6.25 10 6.25 10 6.25M10 6.25V17.5M2.8125 9.375H17.8125C18.3303 9.375 18.75 8.95527 18.75 8.4375V7.1875C18.75 6.66973 18.3303 6.25 17.8125 6.25H2.8125C2.29473 6.25 1.875 6.66973 1.875 7.1875V8.4375C1.875 8.95527 2.29473 9.375 2.8125 9.375Z"
      stroke="#0F172A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Present',
};
</script>

<style scoped></style>
