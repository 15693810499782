<template>
  <li>
    <a :href="href" class="link">
      <slot />
    </a>
  </li>
</template>

<script>
export default {
  props: ['href'],
};
</script>
<style>
.link {
  @apply px-4 py-3 block  text-ol-black hover:bg-ol-secondary-20 hover:rounded transition-colors duration-150 ease-in-out;
}
</style>
