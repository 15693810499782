<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="heroicons-outline/chat-bubble-left-ellipsis">
      <path
        id="Vector"
        d="M7.1875 8.125C7.1875 8.29759 7.04759 8.4375 6.875 8.4375C6.70241 8.4375 6.5625 8.29759 6.5625 8.125C6.5625 7.95241 6.70241 7.8125 6.875 7.8125C7.04759 7.8125 7.1875 7.95241 7.1875 8.125ZM7.1875 8.125H6.875M10.3125 8.125C10.3125 8.29759 10.1726 8.4375 10 8.4375C9.82741 8.4375 9.6875 8.29759 9.6875 8.125C9.6875 7.95241 9.82741 7.8125 10 7.8125C10.1726 7.8125 10.3125 7.95241 10.3125 8.125ZM10.3125 8.125H10M13.4375 8.125C13.4375 8.29759 13.2976 8.4375 13.125 8.4375C12.9524 8.4375 12.8125 8.29759 12.8125 8.125C12.8125 7.95241 12.9524 7.8125 13.125 7.8125C13.2976 7.8125 13.4375 7.95241 13.4375 8.125ZM13.4375 8.125H13.125M1.875 10.6328C1.875 11.967 2.81118 13.1284 4.13122 13.3225C5.03631 13.4555 5.95126 13.5583 6.875 13.6297V17.5L10.3612 14.0138C10.5335 13.8415 10.7661 13.7435 11.0097 13.7375C12.656 13.6967 14.2776 13.5565 15.8687 13.3226C17.1888 13.1285 18.125 11.9671 18.125 10.6329V5.61713C18.125 4.28288 17.1888 3.12152 15.8687 2.92745C13.9534 2.64584 11.9938 2.5 10.0002 2.5C8.00647 2.5 6.04676 2.64587 4.13122 2.92752C2.81118 3.12161 1.875 4.28297 1.875 5.6172V10.6328Z"
        stroke="#0F172A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
