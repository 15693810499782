<template>
  <MainNavDropdownList class="w-80">
    <MainNavDropdownTitle>{{ navItems.title }}</MainNavDropdownTitle>
    <MainNavDropdownListLink :href="item.route" :key="item.name" v-for="item in navItems.items">
      <div class="flex gap-4">
        <keep-alive>
          <component class="my-auto" :is="item.icon" />
        </keep-alive>
        <div>
          <header class="font-bold text-base text-ol-dark-ink-200">{{ item.name }}</header>
          <p class="text-dark-pewter-50">{{ item.description }}</p>
        </div>
      </div>
    </MainNavDropdownListLink>
  </MainNavDropdownList>
</template>

<script>
import MainNavDropdownList from '../partials/MainNavDropdownList.vue';
import MainNavDropdownListLink from '../partials/MainNavDropdownListLink.vue';
import MainNavDropdownTitle from '../partials/MainNavDropdownTitle.vue';
import DropdownNotSureWhereToStart from '../partials/DropdownNotSureWhereToStart.vue';
import Gift from '../../Icon/Gift.vue';
import Scale from '../../Icon/Scale.vue';

import { ref } from 'vue';

export default {
  components: {
    DropdownNotSureWhereToStart,
    MainNavDropdownTitle,
    MainNavDropdownList,
    MainNavDropdownListLink,
    Gift,
    Scale,
  },
  setup() {
    const navItems = ref({
      title: 'Get support after loss',
      items: [
        {
          name: 'Bereavement Support Hubs',
          description: 'Build a supportive community',
          icon: 'gift',
          route: route('bereavement'),
        }, {
          name: 'Probate',
          description: 'Your guide through probate',
          icon: 'scale',
          route: route('probate'),
        },

      ],
    });
    return { navItems };
  },
};
</script>
