<template>
  <Transition name="fade">
    <div class="absolute translate-y-10 -translate-x-1/4 z-10 flex max-w-max">
      <div
        class="max-w-md lg:max-w-2xl flex-auto flex overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg"
      >
        <ul class="flex-1">
          <component :is="content" />
        </ul>
      </div>
    </div>
  </Transition>
</template>

<script>
import AboutNavContent from './content/AboutNavContent.vue';
import EndOfLifeNavContent from './content/EndOfLifeNavContent.vue';
import BereavementContent from './content/BereavementNavContent.vue';

export default {
  components: { EndOfLifeNavContent, AboutNavContent, BereavementContent },
  props: {
    title: String,
    content: String,
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
