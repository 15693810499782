<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    stroke="#160535"
  >
    <rect
      width="25"
      height="25"
      transform="translate(0 0.777344)"
      stroke="none"
    />
    <path
      d="M10.2221 17.3438L9.375 20.3086L8.52792 17.3438C8.083 15.7866 6.86575 14.5693 5.30853 14.1244L2.34375 13.2773L5.30853 12.4303C6.86576 11.9853 8.083 10.7681 8.52792 9.21087L9.375 6.24609L10.2221 9.21088C10.667 10.7681 11.8842 11.9853 13.4415 12.4303L16.4062 13.2773L13.4415 14.1244C11.8842 14.5693 10.667 15.7866 10.2221 17.3438Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.0197 9.85499L18.75 10.9336L18.4803 9.85499C18.1654 8.59539 17.182 7.61189 15.9224 7.297L14.8438 7.02734L15.9224 6.75769C17.182 6.44279 18.1654 5.4593 18.4803 4.1997L18.75 3.12109L19.0197 4.1997C19.3346 5.4593 20.318 6.44279 21.5776 6.75769L22.6562 7.02734L21.5776 7.297C20.318 7.61189 19.3346 8.59539 19.0197 9.85499Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5982 22.2016L17.1875 23.4336L16.7768 22.2016C16.5435 21.5017 15.9944 20.9525 15.2945 20.7193L14.0625 20.3086L15.2945 19.8979C15.9944 19.6646 16.5435 19.1155 16.7768 18.4156L17.1875 17.1836L17.5982 18.4156C17.8315 19.1155 18.3806 19.6646 19.0805 19.8979L20.3125 20.3086L19.0805 20.7193C18.3806 20.9525 17.8315 21.5017 17.5982 22.2016Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script></script>
