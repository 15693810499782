<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="heroicons-outline/arrow-left">
      <path
        id="Vector"
        d="M11 20L3.5 12.5M3.5 12.5L11 5M3.5 12.5H21.5"
        stroke="#7457E8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
